import React from 'react'
import * as Icon from 'react-feather'
import { CgCalendarToday } from 'react-icons/cg'
import { AiOutlineFileSync, AiOutlineFieldTime } from 'react-icons/ai'
import {
  HiOutlineUserGroup,
  HiOutlineOfficeBuilding,
  HiOutlineShieldCheck,
} from 'react-icons/hi'

const icons = {
  home: <Icon.Home size={20} />,
  circle: <Icon.Circle size={12} />,
  role: <Icon.User size={20} />,
  filemanager: <Icon.Folder size={20} />,
  formtemplate: <Icon.FileText size={20} />,
  deadline: <CgCalendarToday size={23} />,
  reporting: <AiOutlineFileSync size={22} />,
  employee: <HiOutlineUserGroup size={20} />,
  site: <HiOutlineOfficeBuilding size={20} />,
  company: <HiOutlineOfficeBuilding size={20} />,
  accessright: <HiOutlineShieldCheck size={20} />,
  logactivity: <AiOutlineFieldTime size={22} />,
}

export default icons
