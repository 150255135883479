import icons from './iconConfig'

const navigationConfig = [
  {
    id: 'home',
    title: 'Beranda',
    type: 'item',
    icon: icons['home'],
    permissions: ['admin', 'editor'],
    navLink: '/dashboard',
  },
  {
    id: 'deadline',
    title: 'Deadline',
    type: 'item',
    icon: icons['deadline'],
    permissions: ['admin', 'editor'],
    navLink: '/deadline',
  },
  {
    id: 'reporting',
    title: 'Reporting',
    type: 'item',
    icon: icons['reporting'],
    permissions: ['admin', 'editor'],
    navLink: '/reporting',
  },

  {
    type: 'groupHeader',
    groupTitle: 'Dokumen & Berkas',
  },
  {
    id: 'filemanager',
    title: 'File Manager',
    type: 'item',
    icon: icons['filemanager'],
    permissions: ['admin', 'editor'],
    navLink: '/document/manage',
  },
  {
    id: 'formtemplate',
    title: 'Form Pengajuan Template',
    type: 'item',
    icon: icons['formtemplate'],
    permissions: ['admin', 'editor'],
    navLink: '/document/template',
  },

  {
    type: 'groupHeader',
    groupTitle: 'Internal',
  },
  {
    id: 'employee',
    title: 'Karyawan',
    type: 'item',
    icon: icons['employee'],
    permissions: ['admin', 'editor'],
    navLink: '/internal/employee',
  },
  {
    id: 'site',
    title: 'Site',
    type: 'item',
    icon: icons['site'],
    permissions: ['admin', 'editor'],
    navLink: '/internal/site',
  },

  {
    type: 'groupHeader',
    groupTitle: 'Data Master Employee',
  },
  {
    id: 'employeedivision',
    title: 'Divisi',
    type: 'item',
    icon: icons['circle'],
    permissions: ['admin'],
    navLink: '/employee/division',
  },
  {
    id: 'employeeposition',
    title: 'Jabatan',
    type: 'item',
    icon: icons['circle'],
    permissions: ['admin'],
    navLink: '/employee/position',
  },
  {
    id: 'employeeidentities',
    title: 'Identities',
    type: 'item',
    icon: icons['circle'],
    permissions: ['admin'],
    navLink: '/employee/identities',
  },
  {
    id: 'employeereligions',
    title: 'Religions',
    type: 'item',
    icon: icons['circle'],
    permissions: ['admin'],
    navLink: '/employee/religions',
  },
  {
    id: 'employeemaritals',
    title: 'Maritals',
    type: 'item',
    icon: icons['circle'],
    permissions: ['admin'],
    navLink: '/employee/maritals',
  },

  {
    type: 'groupHeader',
    groupTitle: 'Setting',
  },
  {
    id: 'role',
    title: 'Role',
    type: 'item',
    icon: icons['role'],
    permissions: ['admin', 'editor'],
    navLink: '/settings/role',
  },
  {
    id: 'accessright',
    title: 'Hak Akses Admin Panel',
    type: 'item',
    icon: icons['accessright'],
    permissions: ['admin', 'editor'],
    navLink: '/settings/access-right',
  },
  {
    id: 'company',
    title: 'Master Perusahaan',
    type: 'item',
    icon: icons['company'],
    permissions: ['admin', 'editor'],
    navLink: '/master/company',
  },

  {
    type: 'groupHeader',
    groupTitle: 'Sistem Logs',
  },
  {
    id: 'logactivity',
    title: 'Log Aktivitas',
    type: 'item',
    icon: icons['logactivity'],
    permissions: ['admin', 'editor'],
    navLink: '/log/activity',
  },
]

export default navigationConfig
