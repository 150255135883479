import { baseURL } from '../../../utils/apihost'
import { fetchGet, fetchPost } from '../../../utils/format'

export default {
  async get(token) {
    let responseJSON = await fetchGet(baseURL + `/profile`, token)
    return responseJSON
  },

  async updatePassword(oldpassword, newpassword, confirmpassword, token) {
    let form = JSON.stringify({
      oldpassword,
      newpassword,
      confirmpassword,
    })
    let responseJSON = await fetchPost(baseURL + '/renewpassword', form, token)
    return responseJSON
  },

  async addDeviceToken(device_token, token) {
    let form = JSON.stringify({ device_token })
    let responseJSON = await fetchPost(
      baseURL + '/registerdevicetoken',
      form,
      token,
    )
    return responseJSON
  },

  async removeDeviceToken(device_token, token) {
    let form = JSON.stringify({ device_token })
    let responseJSON = await fetchPost(
      baseURL + '/removedevicetoken',
      form,
      token,
    )
    return responseJSON
  },

  async getNotifications(page, per_page, token) {
    let responseJSON = await fetchGet(
      baseURL + `/notifications?page=${page}&per_page=${per_page}`,
      token,
    )
    return responseJSON
  },

  async readNotifications(notification_ids, token) {
    let form = JSON.stringify({ notification_ids })
    let responseJSON = await fetchPost(
      baseURL + '/readnotification',
      form,
      token,
    )
    return responseJSON
  },
}
